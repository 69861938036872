import styled from '@emotion/styled';

export const Container = styled.div<{ scroll: boolean }>`
  max-width: ${props => props.theme.desktopContainerWidth};
  margin: 0 auto;
  padding: 1rem;
  
  overflow-y: ${props => props.scroll ? 'auto' : 'unset'};

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    max-width: ${props => props.theme.mobileContainerWidth};
  }
`;
