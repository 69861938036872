import api from './api';
import { PollProxy } from '../models/proxies/poll.proxy';
import { environment } from '../environments/environment';
import { triggerEvent } from './analytics';

/**
 * Obtém todas as votações inicializadas
 */
export async function getPolls(): Promise<PollProxy[]> {
  const { data } = await api.get<PollProxy[]>('/polls/list/initialized');

  return data;
}

/**
 * Insere um voto pelo SQS
 *
 * @param pollId A identificação da enquete
 * @param optionId A identificação da opção votada
 * @param captchaToken O token do reCAPTCHA
 */
async function createPollEntrySqs(pollId: string, optionId: string, captchaToken: string): Promise<boolean> {
  const init: RequestInit = {
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      pollId: pollId,
      pollOptionId: optionId,
      recaptchaToken: captchaToken,
      ip: '::1',
    }),
    method: 'POST',
    mode: 'no-cors',
  };

  // Tenta inserir usando fetch
  return await fetch(environment.api.baseUrl + '/polls/options/entries/sqs', init)
    .then(response => response.status < 300);
}

/**
 * Insere um voto pela API
 *
 * @param pollId A identificação da enquete
 * @param optionId A identificação da opção votada
 * @param captchaToken O token do reCAPTCHA
 */
async function createPollEntryApi(pollId: string, optionId: string, captchaToken: string): Promise<void> {
  const url = `/polls/${encodeURIComponent(pollId)}/options/${encodeURIComponent(optionId)}/entries`;

  await api.post<PollProxy>(url, undefined, {
    headers: {
      'x-recaptcha-token': captchaToken,
    },
  });
}

/**
 * Realiza a adição de um voto
 *
 * @param pollId A identificação da enquete
 * @param optionId A identificação da opção votada
 * @param captchaToken O token do reCAPTCHA
 */
export async function createPollEntry(pollId: string, optionId: string, captchaToken: string): Promise<void> {
  triggerEvent('voted', { poll_id: pollId, option_id: optionId });

  const success = await createPollEntrySqs(pollId, optionId, captchaToken)
    .catch(() => false);

  // Se o SQS falhar, tentaremos novamente pela API
  if (success)
    return;

  await createPollEntryApi(pollId, optionId, captchaToken);
}
