import styled from '@emotion/styled';

export const Link = styled.a`
  margin: 1rem auto;
  user-select: none;
  width: 100%;
  max-width: 30rem;
  height: 10rem;
  display: block;
`;

export const Banner = styled.img`
  width: 100%;
  border-radius: 0.5rem;
  display: block;
`;
