import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { PollsContainer, Title } from './styles';
import { PollCardCarousel } from '../../components/PollCardCarousel';
import { PollProxy } from '../../models/proxies/poll.proxy';
import { ErrorMessage } from '../../components/ErrorMessage';
import { usePolls } from '../../hooks/usePolls';
import { Spinner } from '../../components/Spinner';
import { AdBanner } from '../../components/AdBanner';

const placeholderPolls: PollProxy[] = [
  {
    id: '',
    title: 'Carregando...',
    isActive: false,
    createdAt: '',
    updatedAt: '',
    status: 0,
    options: [],
  },
];

export function HomePagePolls(): ReactElement {
  const { isLoading, data, error } = usePolls();

  if (error && !data) {
    return <ErrorMessage error={error} />;
  }

  if (data && data.length === 0) {
    return (
      <PollsContainer>
        <Title>Nenhuma votação aberta no momento.</Title>
      </PollsContainer>
    );
  }

  if (data && data.length === 1) {
    return <Redirect to={`/poll/${encodeURIComponent(data[0].id)}`} />
  }

  return (
    <Spinner spinning={isLoading}>
      <PollsContainer>
        <Title>Vote agora! É fácil!</Title>
        <PollCardCarousel polls={data ?? placeholderPolls} />
      </PollsContainer>
    </Spinner>
  );
}
