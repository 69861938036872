import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: block;
  position: relative;
  user-select: none;
  
  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    &:before {
      display: block;
      content: '';

      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      margin: 1rem;
      border-radius: 0.5rem;
      box-shadow: 0 1.4rem 0.5rem ${props => props.theme.colors.page.shadow};
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: 60%;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    padding-top: 100%;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
  
  display: block;
  
  border-radius: 0;
  z-index: 1;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    border-radius: 0.75rem;
  }
`;

export const Label = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 2em;
  text-transform: uppercase;
  white-space: nowrap;
  
  color: #0A0301;
  background: #fff;
  border-radius: 1rem;
  padding: 0 2rem;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    transform: translate(-50%, 50%);
  }
`;
