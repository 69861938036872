export const environment = {
  defaultUnprotectedRoute: '/',
  defaultProtectedRoute: '/main',
  enablePWA: false,
  enableRecaptcha: false,
  zustandStoreVersion: 1,
  socials: {
    site: process.env.REACT_APP_SITE_URL,
    facebook: process.env.REACT_APP_FACEBOOK_URL,
    instagram: process.env.REACT_APP_INSTAGRAM_URL,
    tiktok: process.env.REACT_APP_TIKTOK_URL,
    youtube: process.env.REACT_APP_YOUTUBE_URL,
  },
  ads: {
    goshowAndroid: process.env.REACT_APP_GOLSHOW_ANDROID_URL,
    goshowIOS: process.env.REACT_APP_GOLSHOW_IOS_URL,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL,
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
    auth: {
      local: '/auth/local',
    },
    user: {
      me: '/users/me',
    },
    leads: {
      create: '/leads',
    },
  },
};
