import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BasePage } from '../../components/BasePage';
import { Header } from '../../components/Header';
import { ContentContainer } from '../../components/ContentContainer';
import { AdBanner } from '../../components/AdBanner';
import { StatsPageResult } from './result';
import { Button, ButtonContainer, ResultContainer, TitleBanner } from './styles';
import { usePoll } from '../../hooks/usePolls';

export function StatsPage(): ReactElement {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading, data } = usePoll(id);

  return (
    <BasePage>
      <Header />
      <ContentContainer scroll>
        <ResultContainer>
          <TitleBanner imageUrl={data?.imageUrl} alt={data?.title} text="Voto computado com sucesso!" />
          <StatsPageResult isLoading={isLoading} data={data} />
        </ResultContainer>
        <AdBanner />
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={() => history.push(`/poll/${encodeURIComponent(id)}`)}>
          Votar Novamente
        </Button>
      </ButtonContainer>
    </BasePage>
  );
}
