import styled from '@emotion/styled';

export const Button = styled.button`
  background: linear-gradient(0deg, #3C6C9F, #343475, #2E0452, #12003C);
  margin: 0.75rem 1rem;
  padding: 0.15em;
  border: none;
  border-radius: 2em;
  display: block;
  width: calc(100% - 2rem);
  user-select: none;
  cursor: pointer;
  
  transition: all 0.1s ease-in-out;
  
  &:hover {
    transform: scale(1.01);
  }
  
  &:disabled {
    opacity: 0.75;
    cursor: no-drop;

    &:hover {
      transform: none;
    }
  }
`;

export const ButtonContent = styled.div<{ yellow: boolean }>`
  background: ${props => props.yellow ? 'linear-gradient(180deg, #EFBF38, #E08600)' : 'linear-gradient(180deg, #44393B, #2D2424)'};
  color: ${props => props.yellow ? '#2D2424' : '#fff'};
  font-size: 0.9em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  border-top: 0.05em solid #AA9797;
  border-bottom: 0.05em solid #0A0301;
  border-radius: 2em;
  padding: 0.5em;
`;
