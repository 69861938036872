import { ReactElement, ReactNode } from 'react';
import { BackButton, Container } from './styles';

import backIcon from '../../assets/images/back-icon.png';

export interface BaseModalProps {
  onClose: () => void;
  children: ReactNode;
}

export function BaseModal(props: BaseModalProps): ReactElement {
  return (
    <Container>
      <div onClick={props.onClose}>
        <BackButton>
          <img src={backIcon} alt="Voltar" />
        </BackButton>
      </div>

      {props.children}
    </Container>
  );
}
