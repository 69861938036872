import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { BasePage } from '../../components/BasePage';
import { VoteButton } from '../../components/VoteButton';

export const Page = styled(BasePage)`
  padding-bottom: 2rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: #FFF;
`;

export const Link = styled(NavLink)``;

export const Button = styled(VoteButton)``;
