import styled from '@emotion/styled';

export const Title = styled.h6`
  font-size: 0.85rem;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.page.text};
  margin: 2rem auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Button = styled.a`
  background: ${props => props.theme.colors.socials.background};
  box-shadow: 0 0.2rem 0.3rem ${props => props.theme.colors.page.shadow};
  color: #fff;
  border-radius: 3rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin: 0 1rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }
  
  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }
`;
