import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { triggerPageView } from '../../services/analytics';

export function usePageTracking(): void {
  const location = useLocation();

  useEffect(
    () => triggerPageView(location.pathname + location.search, location.pathname),
    [location]
  );
}
