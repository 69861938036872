import styled from '@emotion/styled';
import { OptionCard } from '../../components/OptionCard';
import { Description } from '../../components/OptionCard/styles';

export const CaptchaContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  
  @media (max-width: 325px) {
    iframe {
      transform: scale(0.9);
    }  
  }
  
  @media (max-width: 300px) {
    iframe {
      transform: scale(0.75);
    }
  }
`;

export const ModalOptionCard = styled(OptionCard)`
  width: 21rem;
  max-width: calc(100vw - 1rem);
  font-size: 1.25rem;
  
  ${Description} {
    display: block;
    -webkit-line-clamp: none;
    overflow: unset;
    
    min-height: 3.75em;
    height: auto;
  }
`;
