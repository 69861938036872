import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import PublicRoute from './components/PublicRoute';
import useAuthStore from './store/useAuth';
import { HomePage } from './pages/HomePage';
import PollPage from './pages/PollPage';
import { StatsPage } from './pages/StatsPage';
import { AppEffects } from './App.effects';

function AppRouting(): ReactElement | null {
  const isLogged = useAuthStore((state) => state.isLogged);

  if (isLogged === null) return null;

  return (
    <Suspense fallback={<p>Carregando...</p>}>
      <BrowserRouter>
        <AppEffects />

        <Switch>
          <PublicRoute exact path="/">
            <HomePage />
          </PublicRoute>

          <PublicRoute exact path="/poll/:id">
            <PollPage />
          </PublicRoute>

          <PublicRoute exact path="/poll/:id/done">
            <StatsPage />
          </PublicRoute>

          <Route default>
            <Redirect to="/"/>
          </Route>
        </Switch>
     </BrowserRouter>
    </Suspense>
  );
}

export default AppRouting;
