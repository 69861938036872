import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { UseThemeStore } from './models';
import { setMode } from './services';

export function createUseThemeStore(): UseStore<UseThemeStore> {
  return create(
    devtools(
      (set) => ({
        mode: 'dark',
        setMode: setMode(set),
      })
    ),
  );
}
