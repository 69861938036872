import create, { UseStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { environment } from '../../environments/environment';
import { getStoreName } from '../utils/functions';
import { UseVotesStore } from './models';
import { addVote } from './services';

export function createUseVotesStore(): UseStore<UseVotesStore> {
  const name = getStoreName('useVotesStore');

  return create(
    devtools(
      persist((set) => ({
        lastVote: {},
        addVote: addVote(set),
      }), {
        name,
        version: environment.zustandStoreVersion,
        onRehydrateStorage: () => {
          return (state) => {
            state.lastVote = {
              pollId: state.lastVote?.pollId,
              optionId: state.lastVote?.optionId,
            };
          };
        },
      }),
      name,
    ),
  );
}
