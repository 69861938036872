import styled from '@emotion/styled';
import { BasePage } from '../../components/BasePage';
import { ContentContainer } from '../../components/ContentContainer';

export const Page = styled(BasePage)`
  padding-bottom: 2rem;
`;

export const PollsContainer = styled.div`
  max-width: ${props => props.theme.desktopContainerWidth};
  margin: 2rem auto;
  padding: 0 1rem;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    grid-template-columns: 1fr;
    margin: 1rem 0;
    padding: 0;
  }
`;

export const SocialsContainer = styled(ContentContainer)`
  padding: 0;
`;

export const Divider = styled.div`
  background: ${props => props.theme.colors.page.divider};
  height: 0.1rem;
  width: 50%;
  margin: 3rem auto;
`;

export const Title = styled.h2`
  flex: 1;
  color: ${props => props.theme.colors.page.text};
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 800;
  margin: 1rem auto 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    font-size: 1.4rem;
  }

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    font-size: 1.4rem;
    margin: 1rem auto 0;
  }
`;
