import styled from '@emotion/styled';
import { VoteButton } from '../../components/VoteButton';
import { VoteBanner } from '../../components/VoteBanner';

export const ResultContainer = styled.div`
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TitleBanner = styled(VoteBanner)`
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  background: radial-gradient(circle farthest-corner at 33% 33%, #3C6C9F, #343475, #2E0452, #12003C);
  background-size: 150%;
  
  width: 100%;
  padding: 1rem;
`;

export const Button = styled(VoteButton)`
  max-width: ${props => props.theme.mobileContainerWidth};
  margin: 0 auto;
  font-size: 1.5rem;
`;
