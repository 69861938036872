import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { PollCard } from '../PollCard';
import { Bullet, BulletContainer, CardWrapper, Container, Wrapper } from './styles';
import { PollProxy } from '../../models/proxies/poll.proxy';

export interface PollCardCarouselProps {
  polls: PollProxy[];
}

function scrollToCard(div: HTMLDivElement, index: number): void {
  const card = div?.querySelector('.poll_card[data-index="' + index + '"]');

  if (!card)
    return;

  const left = card.getBoundingClientRect().left - div.getBoundingClientRect().left + div.scrollLeft;

  div.scrollTo({ left: left, behavior: 'smooth' });
}

function findClosestCardIndex(div: HTMLDivElement): number {
  const cards = div.querySelectorAll('.poll_card');

  const divRect = div.getBoundingClientRect();

  let closerCard: HTMLElement;
  let closerCardDistance = Infinity;

  cards.forEach(card => {
    const cardRect = card.getBoundingClientRect();
    const distance = Math.abs(divRect.left - cardRect.left);

    if (distance < closerCardDistance) {
      closerCardDistance = distance;
      closerCard = card as HTMLElement;
    }
  });

  return closerCard ? +closerCard.dataset.index : 0;
}

export function PollCardCarousel({ polls }: PollCardCarouselProps): ReactElement {
  const ref = useRef<HTMLDivElement>();
  const [current, setCurrent] = useState<number>(0);
  const lastIndex = polls.length - 1;

  function scrollTo(index: number) {
    scrollToCard(ref.current, index);
  }

  function onScroll() {
    setCurrent(findClosestCardIndex(ref.current));
  }

  useEffect(() => {
    const nextIndex = current < lastIndex ? current + 1 : 0;
    const timer = setTimeout(() => scrollTo(nextIndex), 5000);

    return () => clearTimeout(timer);
  }, [lastIndex, current]);

  return (
    <div>
      <Wrapper ref={ref} onScroll={onScroll}>
        <Container>
          {polls.map((poll, i) =>
            <CardWrapper key={i} data-index={i} className="poll_card">
              <PollCard poll={poll} />
            </CardWrapper>
          )}
        </Container>
      </Wrapper>
      <BulletContainer>
        {polls.map((poll, i) =>
          <Bullet key={i} isActive={i === current} onClick={() => scrollTo(i)} />
        )}
      </BulletContainer>
    </div>
  );
}
