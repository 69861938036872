import { ThemeProvider } from '@emotion/react';
import React, { ReactElement } from 'react';
import { hot } from 'react-hot-loader/root';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import AppRouting from './App.routing';
import { GlobalStyles } from './styles/global';
import { darkTheme, lightTheme } from './App.theming';
import useTheme from './store/useTheme';

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): ReactElement | null {
  const theme = useTheme(s => s.mode);

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <QueryClientProvider client={defaultQueryClient}>
        <GlobalStyles/>
        <AppRouting/>
        <ReactQueryDevtools initialIsOpen={false}/>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
