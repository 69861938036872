import styled from '@emotion/styled';

export const Container = styled.div`
  background: radial-gradient(circle farthest-corner at 40% 0%, #3C6C9F, #343475, #2E0452, #12003C);
  border-radius: 1rem;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 1rem;
  padding: 1rem 2rem;
  
  user-select: none;
`;

export const ImageContainer = styled.div`
  flex: 1;
  
  img {
    display: block;
    margin: 0 auto;
    width: 90%;
    height: 10rem;
    max-height: 10rem;
    object-fit: contain;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 2em;
  text-transform: uppercase;
  white-space: nowrap;
  
  color: #0A0301;
  background: #fff;
  border-radius: 1rem;
  padding: 0 2rem;
`;


export const Text = styled.p`
  flex: 1;
  color: ${props => props.theme.colors.page.text};
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 800;
  margin: 1rem;
  width: 50%;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    font-size: 1.1rem;
  }
`;
