import { SetState } from 'zustand';
import { createPollEntry } from '../../services/polls';
import { UseVotesStore } from './index';

export function addVote(set: SetState<UseVotesStore>): UseVotesStore['addVote'] {
  return async function (queryClient, pollId: string, optionId: string, captchaToken: string) {
    try {
      await createPollEntry(pollId, optionId, captchaToken);

      set({
        lastVote: {
          pollId,
          optionId,
        },
      });
    } catch (e) {
      await queryClient.invalidateQueries(['polls']);

      throw e;
    }
  };
}
