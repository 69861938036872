import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Divider, Page, SocialsContainer } from './styles';
import { SocialMedia } from '../../components/SocialMedia';
import { HomePagePolls } from './polls';
import { AdBanner } from '../../components/AdBanner';
import { ContentContainer } from '../../components/ContentContainer';

export function HomePage(): ReactElement {
  return (
    <Page>
      <Header />
      <HomePagePolls />
      <SocialsContainer>
        <Divider />
        <SocialMedia />
      </SocialsContainer>
      <ContentContainer>
        <AdBanner />
      </ContentContainer>
    </Page>
  );
}
