import { ReactElement } from 'react';
import { Message } from './styles';
import { getErrorMessage } from '../../utils/query';

export interface ErrorMessageProps {
  error: any;
}

export function ErrorMessage({ error }: ErrorMessageProps): ReactElement {
  return (
    <Message>
      <p>Ocorreu um erro ao realizar a solicitação:</p>
      <p>{getErrorMessage(error?.response?.data) || error?.message || error?.toString()}</p>
    </Message>
  )
}
