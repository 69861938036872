import styled from '@emotion/styled';

export const Container = styled.header`
  user-select: none;
  
  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    max-width: ${props => props.theme.mobileContainerWidth};
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  background: ${props => props.theme.colors.header.desktopBackground};

  display: grid;
  /*grid-template-columns: 3rem 1fr 3rem;*/
  grid-template-columns: 1fr;
  align-items: center;

  height: 6rem;
  padding: 0 2rem;

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    background: ${props => props.theme.colors.header.background};
    border-radius: 2rem;
    height: 5rem;
    padding: 0 1rem;
    margin: 1rem;
  }
`;

export const Logo = styled.div`
  display: block;
  text-align: center;
  
  img {
    object-fit: contain;
    height: 4rem;
    max-width: 100%;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: none;
  background: ${props => props.theme.colors.header.buttonBackground};
  transition: all 0.1s ease-in-out;
  
  &:hover {
    transform: scale(1.1);
  }
  
  img {
    width: 1.75rem;
    height: 1.75rem;
    object-fit: contain;
    display: block;
  }
`;
