import { ReactElement } from 'react';
import { Button, Container, Content, Logo } from './styles';
import logoLight from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';
import mouseIcon from '../../assets/images/mouse-icon.png';
import menuIcon from '../../assets/images/menu-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import { Link, useHistory } from 'react-router-dom';
import useTheme from '../../store/useTheme';
import { environment } from '../../environments/environment';

export function Header(): ReactElement {
  const theme = useTheme(s => s.mode);
  //const history = useHistory();

  //const canGoBack = history.location.pathname !== '/' && history.length > 0;

  return (
    <Container>
      <Content>
        {/*{canGoBack ? (
          <Button onClick={() => history.goBack()}>
            <img src={backIcon} alt="Voltar" />
          </Button>
        ) : (
          <div />
        )}*/}

        <Logo>
          <a href={environment.socials.site} target="_blank" rel="noreferrer">
            <img src={theme === 'dark' ? logoDark : logoLight} alt="Programa do Ratinho" />
          </a>
        </Logo>

        {/*<Button>
          <img src={menuIcon} alt="Menu" />
        </Button>*/}
        {/*<div />*/}
      </Content>
    </Container>
  );
}
