import { ReactElement, ReactNode } from 'react';
import { Container, Content, Description, Image, ImageContainer, Title } from './styles';
import { PollOptionProxy } from '../../models/proxies/poll-option.proxy';

import defaultThumbnail from '../../assets/images/default-thumbnail.png';

export interface OptionCardProps {
  option: PollOptionProxy;
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
}

export function OptionCard({ option, onClick, className, children }: OptionCardProps): ReactElement {
  return (
    <Container className={className}>
      <ImageContainer onClick={onClick}>
        <Image src={option.imageUrl || defaultThumbnail} alt={option.title} />
      </ImageContainer>

      <Content>
        <Title>{option.title}</Title>
        <Description>{option.description}</Description>
        {children}
      </Content>
    </Container>
  );
}
