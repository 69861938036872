import { ReactElement, ReactNode } from 'react';
import { Container } from './styles';

export interface ContentContainerProps {
  scroll?: boolean;
  children: ReactNode;
  className?: string;
}

export function ContentContainer(props: ContentContainerProps): ReactElement {
  return (
    <Container scroll={props.scroll} className={props.className}>
      {props.children}
    </Container>
  );
}
