import { ReactElement } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Header } from '../../components/Header';
import { ContentContainer } from '../../components/ContentContainer';
import { Spinner } from '../../components/Spinner';
import { usePoll } from '../../hooks/usePolls';
import { PollPageOptions } from './options';
import { PollProxy } from '../../models/proxies/poll.proxy';
import { PollOptionProxy } from '../../models/proxies/poll-option.proxy';
import { VoteBanner } from '../../components/VoteBanner';
import { AdBanner } from '../../components/AdBanner';
import * as S from './styles';

const placeholderOption: PollOptionProxy = {
  id: '',
  pollId: '',
  title: '...',
  imageUrl: '',
  createdAt: '',
  updatedAt: '',
  isActive: false,
};

const placeholderPoll: PollProxy = {
  id: '',
  title: 'Carregando...',
  options: new Array(6).fill(placeholderOption),
  status: 0,
  createdAt: '',
  updatedAt: '',
  isActive: false,
};

function PollPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data, error, hasPollsOpen } = usePoll(id);

  if (error || (!isLoading && !data)) {
    if (hasPollsOpen)
      return <Redirect to="/" />;

    return (
      <S.Page>
        <Header />
        <ContentContainer scroll>
          <S.Title>A votação foi finalizada ou ainda não foi iniciada, por favor, tente novamente mais tarde.</S.Title>

          <S.Link to="/">
            <S.Button>Voltar ao início</S.Button>
          </S.Link>
        </ContentContainer>
        <ContentContainer>
          <AdBanner />
        </ContentContainer>
      </S.Page>
    );
  }

  return (
    <S.Page>
      <Header />
      <ContentContainer scroll>
        <VoteBanner imageUrl={data?.imageUrl} alt={data?.title} />
        <Spinner spinning={ isLoading }>
          <PollPageOptions poll={ data ?? placeholderPoll } />
        </Spinner>
        <AdBanner />
      </ContentContainer>
    </S.Page>
  );
}

export default PollPage;
