import { ReactElement, useMemo } from 'react';
import { PollLeaderboardProxy } from '../../models/proxies/poll-leaderboard.proxy';
import { Container, Content, Description, Image, PercentContainer, Title } from './styles';

import defaultThumbnail from '../../assets/images/default-thumbnail.png';
import { PollOptionProxy } from '../../models/proxies/poll-option.proxy';

export interface LeaderboardItemProps {
  item: PollLeaderboardProxy;
  isCurrent: boolean;
}

export function LeaderboardItem({ item, isCurrent }: LeaderboardItemProps): ReactElement {
  const percent = useMemo(() => Math.round(item.percent).toLocaleString('pt-BR').padStart(2, '0') + '%', [item.percent]);
  const option = item.pollOption;

  return (
    <Container isCurrent={isCurrent}>
      <Image src={option.imageUrl || defaultThumbnail} alt={option.title} />
      <Content>
        <Title>{option.title}</Title>
        <Description>{option.description}</Description>
      </Content>
      <PercentContainer>
        {percent}
      </PercentContainer>
    </Container>
  );
}

export interface VotedItemProps {
  option: PollOptionProxy;
}

export function VotedItem({ option }: VotedItemProps): ReactElement {
  return (
    <Container isCurrent>
      <Image src={option.imageUrl || defaultThumbnail} alt={option.title} />
      <Content>
        <Title>{option.title}</Title>
        <Description>{option.description}</Description>
      </Content>
    </Container>
  );
}
