import { ReactElement } from 'react';
import { Container, Image, ImageContainer, Label } from './styles';
import { PollProxy } from '../../models/proxies/poll.proxy';

import defaultThumbnail from '../../assets/images/default-thumbnail.png';

export interface PollCardProps {
  poll: PollProxy;
}

export function PollCard({ poll }: PollCardProps): ReactElement {
  return (
    <Container to={`/poll/${encodeURIComponent(poll.id)}`}>
      <ImageContainer>
        <Image src={poll.imageUrl || defaultThumbnail} title={poll.title} alt={poll.title} />
      </ImageContainer>
      <Label>Votação Aberta!</Label>
    </Container>
  );
}
