import styled from '@emotion/styled';

export const Container = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.2rem 0.2rem ${props => props.theme.colors.page.shadow};
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 40vh;
  user-select: none;
  
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
`;

export const Content = styled.div`
  background: radial-gradient(circle farthest-corner at top left, #3C6C9F 0%, #343475 33%, #2E0452 75%, #12003C 125%);
  background-size: 150%;
  background-repeat: no-repeat;
  flex-grow: 1;
`;

export const Title = styled.h6`
  font-size: 0.8em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 1rem 0.5rem;
`;

export const Description = styled.p`
  font-size: 0.6em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0 0.5rem;
  
  line-height: 1.25em;
  height: 3.75em;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
