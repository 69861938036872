import Modal from 'antd/lib/modal/Modal';
import React, { ReactElement, useMemo, useState } from 'react';
import { OptionCard, OptionCardProps } from '../../components/OptionCard';
import { VoteButton } from '../../components/VoteButton';
import { ConfirmVoteModal } from '../../modals/ConfirmVoteModal';
import { PollProxy } from '../../models/proxies/poll.proxy';
import { Grid } from './styles';

export interface PollPageOptionsProps {
  poll: PollProxy;
}

export function PollPageOptions({ poll }: PollPageOptionsProps): ReactElement {
  const options = useMemo(() => poll.options, [poll.options]);

  return (
    <Grid>
      { options.map((option, i) =>
        <PollPageOptionCard key={ i } option={ option } />,
      ) }
    </Grid>
  );
}

function PollPageOptionCard({ option }: OptionCardProps): ReactElement {
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

  function toggleConfirm(e?: React.MouseEvent) {
    e?.preventDefault();
    e?.stopPropagation();

    setConfirmOpen(open => !open);
  }

  return (
    <OptionCard option={ option } onClick={ toggleConfirm }>
      <VoteButton onClick={ toggleConfirm } yellow>Votar</VoteButton>

      <Modal modalRender={ () => <ConfirmVoteModal option={ option } onClose={ toggleConfirm } /> }
             onCancel={ toggleConfirm }
             width="auto"
             visible={ isConfirmOpen }
             destroyOnClose />
    </OptionCard>
  );
}
