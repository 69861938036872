import styled from '@emotion/styled';

export const Container = styled.div`
  padding-top: 1rem;
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 1.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
  background: ${props => props.theme.colors.header.buttonBackground};
  transition: all 0.1s ease-in-out;
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    display: block;
  }
`;
