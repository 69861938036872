import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { getAnalytics, logEvent } from 'firebase/analytics';

const app = initializeApp(environment.firebase);

export const analytics = getAnalytics(app);

export function triggerEvent<T>(eventName: string, eventData: T): void {
  logEvent(analytics, eventName, eventData);
}

export function triggerPageView(screen: string, screenClass: string): void {
  logEvent(analytics, 'screen_view', {
    firebase_screen: screen,
    firebase_screen_class: screenClass,
  });
}
