import styled from '@emotion/styled';

export const Container = styled.div<{ isCurrent: boolean }>`
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  padding: 0.5rem;
  
  background: ${props => props.isCurrent ?
          'radial-gradient(circle farthest-corner at 20% 0%, #0DB973, #006E1A)' :
          'transparent'};
  background-size: 200%;
  border-radius: 0.5rem;
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background: #fff;
  user-select: none;
`;

export const Content = styled.div`
  font-size: 2rem;
  padding: 0 1rem;
  flex: 1;
`;

export const Title = styled.strong`
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0.25rem 0;
`;

export const Description = styled.p`
  font-size: 0.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0.5rem 0;

  line-height: 1.25em;
  height: 1.25em;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PercentContainer = styled.div`
  background: radial-gradient(circle farthest-corner at 10% 0%, #3C6C9F, #343475, #2E0452);
  background-size: 150%;
  border-radius: 0.5rem;
  
  font-size: 1.75rem;
  font-weight: 800;
  text-align: center;
  line-height: 4rem;
  min-width: 8rem;
  letter-spacing: 0.1em;
  color: #fff;
`;
