import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    mobileMaxWidth: string,
    mobileContainerWidth: string,
    desktopContainerWidth: string,
    colors: {
      page: {
        background: string,
        text: string,
        bullet: string,
        divider: string,
        shadow: string,
      },
      header: {
        background: string,
        desktopBackground: string,
        buttonBackground: string,
      },
      socials: {
        background: string,
      },
    };
  }
}

export const darkTheme: Theme = {
  mobileMaxWidth: '600px',
  mobileContainerWidth: '30rem',
  desktopContainerWidth: '64rem',
  colors: {
    page: {
      background: 'linear-gradient(0deg, #2D2424 0%, #44393B 100%)',
      text: '#fff',
      bullet: '#0A0301',
      divider: '#0A0301',
      shadow: '#0A030155',
    },
    header: {
      background: 'linear-gradient(180deg, #2D2424 0%, #44393B 100%)',
      desktopBackground: 'linear-gradient(0deg, #2D2424 0%, #44393B 100%)',
      buttonBackground: 'linear-gradient(163deg, #8DE6FF 0%, #7DCEEC 8%, #5694BE 26%, #3C6C9F 37%, #343475 72%, #2E0452 100%)',
    },
    socials: {
      background: 'linear-gradient(180deg, #3C6C9F, #343475, #2E0452)',
    },
  },
};

export const lightTheme: Theme = {
  mobileMaxWidth: '600px',
  mobileContainerWidth: '30rem',
  desktopContainerWidth: '64rem',
  colors: {
    page: {
      background: 'linear-gradient(0deg, #D2D2D2 0%, #EBEBEB 100%)',
      text: '#505456',
      bullet: '#505456',
      divider: '#fff',
      shadow: '#0A030155',
    },
    header: {
      background: 'linear-gradient(180deg, #D2D2D2 0%, #FFFFFF 100%)',
      desktopBackground: 'linear-gradient(0deg, #2D2424 0%, #44393B 100%)',
      buttonBackground: 'linear-gradient(0deg, #2D2424 0%, #44393B 100%)',
    },
    socials: {
      background: 'linear-gradient(0deg, #2D2424 0%, #44393B 100%)',
    },
  },
};
