import { ReactElement, useMemo } from 'react';
import { Banner, Link } from './styles';
import { isIOS } from '../../store/utils/functions';
import { environment } from '../../environments/environment';

import banner from '../../assets/images/goshow-banner.png';

export function AdBanner(): ReactElement {
  const url = useMemo(() => isIOS() ? environment.ads.goshowIOS : environment.ads.goshowAndroid, []);

  return (
    <Link href={url} target="_blank" rel="noreferrer">
      <Banner src={banner} alt="Goshow" />
    </Link>
  );
}
