import styled from '@emotion/styled';

export const Wrapper = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  border-radius: 1.25rem;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    border-radius: 0;
    max-width: 100vw;
  }
`;

export const Container = styled.div`
  display: block;
  white-space: nowrap;
  
  position: relative;
  font-size: 0;
  
  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    &:before, &:after {
      content: '';
      display: inline-block;
      width: calc((100vw - ${props => props.theme.mobileContainerWidth}) / 2);
      height: 100%;
    }
  }
`;

export const CardWrapper = styled.div`
  display: inline-block;
  margin: 0;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease-in-out;
  
  scroll-snap-align: center;
  
  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    padding: 1rem;
    max-width: ${props => props.theme.mobileContainerWidth};
  }
`;

export const BulletContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
`;

export const Bullet = styled.button<{ isActive: boolean }>`
  background: ${props => props.isActive ? '#fff' : props.theme.colors.page.bullet};
  transition: all 0.1s ease-in-out;
  
  border-radius: 0.5rem;
  border: none;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.5rem;
  padding: 0;
  
  cursor: pointer;
`;
