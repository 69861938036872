import { ReactElement } from 'react';
import { Button, Container, Title } from './styles';
import { environment } from '../../environments/environment';

import facebookIcon from '../../assets/images/facebook-icon.png';
import instagramIcon from '../../assets/images/instagram-icon.png';
import tiktokIcon from '../../assets/images/tiktok-icon.png';
import youtubeIcon from '../../assets/images/youtube-icon.png';

export function SocialMedia(): ReactElement {
  return (
    <div>
      <Title>Siga as nossas redes sociais!</Title>
      <Container>
        <Button href={environment.socials.facebook} target="_blank">
          <img src={facebookIcon} alt="Facebook" />
        </Button>
        <Button href={environment.socials.instagram} target="_blank">
          <img src={instagramIcon} alt="Instagram" />
        </Button>
        {/*<Button href={environment.socials.tiktok} target="_blank">
          <img src={tiktokIcon} alt="TikTok" />
        </Button>*/}
        <Button href={environment.socials.youtube} target="_blank">
          <img src={youtubeIcon} alt="YouTube" />
        </Button>
      </Container>
    </div>
  );
}
