import { ReactElement, ReactNode } from 'react';
import Spin from 'antd/lib/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

export interface Spinner {
  spinning: boolean;
  children: ReactNode;
}

export function Spinner(props: Spinner): ReactElement {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} color="#5694BE" spin />;

  return (
    <Spin spinning={props.spinning} indicator={antIcon}>
      {props.children}
    </Spin>
  );
}
