import { ReactElement } from 'react';
import { Container, ImageContainer, Label, Text } from './styles';

import logo from '../../assets/images/votacao-ao-vivo.png';

export interface VoteBannerProps {
  text?: string;
  className?: string;
  imageUrl?: string;
  alt?: string;
}

export function VoteBanner(props: VoteBannerProps): ReactElement {
  return (
    <Container className={props.className}>
      <Label>Votação Aberta!</Label>
      <ImageContainer>
        <img src={props.imageUrl ?? logo} alt={props.alt ?? 'VOTAÇÃO AO VIVO'} />
      </ImageContainer>
      {!!props.text && <Text>{props.text}</Text>}
    </Container>
  );
}
