import { ReactElement, ReactNode } from 'react';
import { Container } from './styles';

export interface BasePageProps {
  className?: string;
  children: ReactNode;
}

export function BasePage(props: BasePageProps): ReactElement {
  return (
    <Container className={props.className}>
      {props.children}
    </Container>
  )
}
