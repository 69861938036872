import { ReactElement, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Spinner } from '../../components/Spinner';
import { VoteButton } from '../../components/VoteButton';
import { environment } from '../../environments/environment';
import { PollOptionProxy } from '../../models/proxies/poll-option.proxy';
import useTheme from '../../store/useTheme';
import useVotesStore from '../../store/useVotes';
import { BaseModal } from '../BaseModal';
import { CaptchaContainer, ModalOptionCard } from './styles';

export interface ConfirmVoteModalProps {
  option: PollOptionProxy;
  onClose: () => void;
}

export function ConfirmVoteModal({ option, onClose }: ConfirmVoteModalProps): ReactElement {
  const history = useHistory();
  const theme = useTheme(s => s.mode);
  const addVote = useVotesStore(s => s.addVote);
  const queryClient = useQueryClient();

  const [captchaToken, setCaptchaToken] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  async function onConfirm() {
    setLoading(true);

    addVote(queryClient, option.pollId, option.id, captchaToken)
      .then(() => history.push(`/poll/${ encodeURIComponent(option.pollId) }/done`))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }

  return (
    <BaseModal onClose={ onClose }>
      <ModalOptionCard option={ option }>
        <Spinner spinning={ isLoading }>

          { environment.enableRecaptcha && (

            <CaptchaContainer>
              <ReCAPTCHA
                sitekey={ environment.api.recaptchaKey }
                theme={ theme === 'dark' ? 'dark' : 'light' }
                onChange={ (code) => setCaptchaToken(code) }
              />
            </CaptchaContainer>
          ) }

          { error && <ErrorMessage error={ error } /> }

          <VoteButton onClick={ onConfirm } yellow>Confirmar</VoteButton>

        </Spinner>
      </ModalOptionCard>
    </BaseModal>
  );
}
