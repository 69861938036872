import { QueryObserverResult, useQuery } from 'react-query';
import { PollProxy } from '../../models/proxies/poll.proxy';
import { getPolls } from '../../services/polls';
import { useMemo } from 'react';

let errorCount: number = 0;

export function usePolls(): QueryObserverResult<PollProxy[]> {
  return useQuery(['polls', 'initialized'], () => getPolls(), {
    cacheTime: 1000 * 60,
    refetchInterval: 5_000,
    onError: () => {
      errorCount++;

      if (errorCount >= 3) {
        window.location.reload();
      }
    },
    onSuccess: () => {
      errorCount = 0;
    },
  });
}

export function usePoll(pollId: string): { isLoading: boolean, error: unknown, data: PollProxy, hasPollsOpen: boolean } {
  const { data, isLoading, error } = usePolls();

  const poll = useMemo(() => data?.find(poll => poll.id === pollId), [data, pollId]);

  return { isLoading, error, data: poll, hasPollsOpen: data && data.length > 0 };
}
