import { ReactElement, useMemo } from 'react';
import { VotedItem } from '../../components/LeaderboardItem';
import { Spinner } from '../../components/Spinner';
import { PollOptionProxy } from '../../models/proxies/poll-option.proxy';
import useVotesStore from '../../store/useVotes';
import { usePoll } from '../../hooks/usePolls';
import { PollProxy } from '../../models/proxies/poll.proxy';

const placeholderOption: PollOptionProxy = {
  id: '',
  pollId: '',
  title: '...',
  description: '',
  imageUrl: '',
  createdAt: '',
  updatedAt: '',
  isActive: false,
};

export interface StatsPageResultProps {
  isLoading: boolean;
  data: PollProxy;
}

export function StatsPageResult({ isLoading, data }: StatsPageResultProps): ReactElement {
  const lastVote = useVotesStore(s => s.lastVote);

  const currentVote = useMemo(
    () => data?.options.find(option => option.id === lastVote?.optionId),
    [lastVote, data]
  );

  if (!isLoading && !currentVote) {
    return <div />;
  }

  return (
    <Spinner spinning={isLoading}>
      <VotedItem option={currentVote ?? placeholderOption} />
    </Spinner>
  );
}
