import { ButtonHTMLAttributes, ReactElement } from 'react';
import { Button, ButtonContent } from './styles';

export interface VoteButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  yellow?: boolean;
}

export function VoteButton({ children, yellow, ...innerProps }: VoteButtonProps): ReactElement {
  return (
    <Button {...innerProps}>
      <ButtonContent yellow={yellow}>{children}</ButtonContent>
    </Button>
  )
}
